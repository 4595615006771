<template>
  <div>
    <errorModal
      :errorMessage="error.message"
      :logoutOnClose="error.forceLogout"
    />
    <div
      v-if="!isLoading && areThereMeasurements"
      :class="['modal', { 'is-active': displayStatusList }]"
    >
      <div class="modal-background" @click="displayStatusList = false"></div>
      <div class="modal-card small-width">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ $t("buttons.changeStatus") }}</p>
        </header>
        <section class="modal-card-body">
          <div class="columns" v-if="measurements[0]">
            <div class="column"></div>
            <div class="column">
              <div class="control">
                <ul>
                  <li
                    v-for="(status, idx) in getStatusListWithoutCurrent(
                      measurements[0].status
                    )"
                    :key="idx"
                    :class="[
                      'is-size-5',
                      {
                        'status-border-bottom':
                          idx !=
                          getStatusListWithoutCurrent(measurements[0].status)
                            .length -
                            1,
                      },
                    ]"
                    @click="changeStatus(status.statusName)"
                  >
                    {{ $t(status.text) }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="column"></div>
          </div>
          <div class="columns" v-if="patientHasBeenDeactivated">
            <div class="column">
              <p class="has-text-danger is-size-7">
                {{ $t("warnings.patientHasBeenDeactivated") }}
              </p>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot"></footer>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="displayStatusList = false"
      ></button>
    </div>
    <Loader v-if="isLoading" />
    <div v-if="!isLoading && areThereMeasurements">
      <div v-for="(measure, idx) in this.measurements" :key="idx">
        <div
          v-if="idx == 1"
          :class="[
            'has-text-left has-top-borders has-text-bold ',
            { 'is-size-7 ': isCurrentRouteForPrintHistory },
            !isCurrentRouteForPrintHistory ? 'mt-3 p-3' : 'p-1',
          ]"
          :style="{ 'height: 50px': isCurrentRouteForPrintHistory }"
        >
          <strong>{{ $t("statusHistory") }}</strong>
        </div>
        <PatientMeasurementTable
          :measure="measure"
          :monitoringProtocol="monitoringProtocol"
          :isLastMeasure="idx == 0"
          :isCurrentRouteForPrintHistory="isCurrentRouteForPrintHistory"
          :showScheduledCall="showScheduledCall"
          :scheduledCall="scheduledCall"
        />
        <PatientMeasurementNote
          :notes="measure.notes"
          :isCurrentRouteForPrintHistory="isCurrentRouteForPrintHistory"
          :isLastMeasure="idx == 0"
          :measurementId="measure.measurementId"
          @handleNewNote="$emit('handleNewNote', $event)"
        />

        <!-- TODO changeStatusButton deve apparire solo quando idx == 0 e la misura [0] è effettivamente l'ultima ricevuta (allMeasurements[0]) -->
        <div
          id="changeStatusButton"
          v-if="
            idx == 0 && measure.date === globalMostRecentMeasurementDatetime
          "
          class="columns"
          v-show="!isCurrentRouteForPrintHistory"
        >
          <div class="column"></div>
          <div class="column is-one-fifth">
            <div class="has-text-centered pl-4 pr-4">
              <button
                :class="[
                  'button is-success is-small fixed-width',
                  { 'is-loading': awaitingResponse },
                ]"
                @click="displayAndSetStatus(measure)"
              >
                {{ $t("buttons.changeStatus") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="
          this.areAnyReceivedDataDifferentFromMeasurementData() &&
          isCurrentRouteForPrintHistory
        "
        class="is-size-7"
      >
        {{ $t("warnings.dateOfreceivedIsDifferentMeasurement") }}
      </div>
    </div>
    <div v-if="!isLoading && !areThereMeasurements">
      <p>{{ $t("warnings.noMeasurements") }}</p>
    </div>
  </div>
</template>

<script>
import PatientMeasurementTable from "./PatientMeasurementTable.vue";
import PatientMeasurementNote from "./PatientMeasurementNote.vue";
import measurementStatusMixin from "../shared/measurementStatusMixin";
import errorMixin from "../Error/errorMixin";
import ErrorModal from "../Error/errorModal";
import Loader from "@/shared/Loader.vue";
import api from "@/API/api";
export default {
  mixins: [measurementStatusMixin, errorMixin],
  emits: ["handleMeasurementStatusChange"],
  components: {
    PatientMeasurementTable,
    PatientMeasurementNote,
    Loader,
    ErrorModal,
  },
  props: [
    "measurements",
    "isCurrentRouteForPrintHistory",
    "patientId",
    "monitoringProtocol",
    "showScheduledCall",
    "scheduledCall",
    "globalMostRecentMeasurementDatetime",
  ],
  data() {
    return {
      displayStatusList: false,
      awaitingResponse: false,
      patientHasBeenDeactivated: false,
      currentStatus: "",
    };
  },
  updated() {
    console.log(
      "Displaying " + JSON.stringify(this.measurements.length) + " measurements"
    );
  },
  methods: {
    async deleteScheduledCall() {
      try {
        await api.deleteScheduledCall(this.patientId);
        this.$emit("handleScheduledCallChange", { scheduledCall: "" });
      } catch (e) {
        this.error.message =
          "Error in deleting scheduled called to patient: " + e;
      }
    },

    async changeStatus(newStatus) {
      if (newStatus != "deactivated") this.displayStatusList = false;
      this.awaitingResponse = true;

      try {
        if (!this.currentMeasurementId)
          throw new Error(
            "Non posso modificare lo stato senza conoscere l'ID misura"
          );
        const res = await api.modifyStatus(this.currentMeasurementId, {
          currentStatus: this.currentStatus,
          newStatus: newStatus,
        });
        if (!res.newStatus)
          console.log("WARNING: api did not send back a new status");
        this.setMeasurementStatus(res.newStatus);
        this.patientHasBeenDeactivated = newStatus == "deactivated";
        if (
          (this.callScheduledForToday || this.callScheduledForAPastDay) &&
          this.statusHasBeenSetByHuman(res.newStatus)
        ) {
          console.log(
            `Patient has been managed for today (${res.newStatus}). Deleting call scheduled at ${this.scheduledCall}`
          );
          await this.deleteScheduledCall();
        }
      } catch (E) {
        this.error.message = JSON.stringify(E);
        // new Date().toISOString() + E.toString() || JSON.stringify(E);
      } finally {
        this.awaitingResponse = false;
      }
    },

    setMeasurementStatus(newStatus) {
      this.$emit("handleMeasurementStatusChange", newStatus);
    },
    displayAndSetStatus(measure) {
      this.displayStatusList = !this.displayStatusList;
      this.currentStatus = measure.status;
      this.currentMeasurementId = measure.measurementId;
      console.log(`Set active measurement to ${measure.measurementId}`);
    },
    areAnyReceivedDataDifferentFromMeasurementData() {
      let find = false;
      this.measurements.forEach((measurement) => {
        if (measurement.receivedAt !== measurement.data) {
          find = true;
        }
      });
      return find;
    },
  },
  computed: {
    isLoading() {
      return !this.measurements;
    },
    areThereMeasurements() {
      return this.measurements && this.measurements.length > 0;
    },
    callScheduledForToday() {
      return (
        new Date().toDateString() ===
        new Date(this.scheduledCall).toDateString()
      );
    },
    callScheduledForAPastDay() {
      return new Date() > new Date(this.scheduledCall);
    },
  },
};
</script>

<style lang="scss" scoped>
.small-width {
  width: 300px;
}
.has-top-borders {
  border-top: 1px solid #dbdbdbdb;
}
.status-border-bottom {
  border-bottom: 1px solid #dbdbdb;
}
li:hover {
  color: $restech-green;
}
</style>