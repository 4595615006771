<template>
  <div>
    <form @submit.prevent="callApi" :class="this.errors ? 'errors' : false">
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.patientName")
            }}</label>
            <div class="control">
              <input
                @invalid="invalidateForm"
                class="input"
                type="text"
                v-model.trim="personalDataCopy.name"
                :placeholder="$t('fieldsDescription.name')"
              />
            </div>
          </div>
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.birthplace")
            }}</label>
            <div class="control">
              <input
                @invalid="invalidateForm"
                class="input"
                type="text"
                v-model.trim="personalDataCopy.birthplace"
                :placeholder="$t('fieldsDescription.city')"
              />
            </div>
          </div>
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.residentialAddress")
            }}</label>
            <div class="control">
              <input
                @invalid="invalidateForm"
                class="input"
                type="text"
                v-model.trim="personalDataCopy.address"
                :placeholder="$t('fieldsDescription.address')"
              />
            </div>
          </div>
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.telephoneContact")
            }}</label>
            <div class="control">
              <input
                @invalid="invalidateForm"
                class="input"
                type="text"
                v-model.trim="personalDataCopy.phone"
                :placeholder="$t('fieldsDescription.number')"
              />
            </div>
          </div>
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.patientSex")
            }}</label>
            <div class="select is-fullwidth">
              <select v-model.trim="personalDataCopy.sex">
                <option value="M">{{ $t("fieldsDescription.male") }}</option>
                <option value="F">{{ $t("fieldsDescription.female") }}</option>
                <option value="Unspecified">N/A</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.patientSurname")
            }}</label>
            <div class="control">
              <input
                @invalid="invalidateForm"
                v-model.trim="personalDataCopy.surname"
                class="input"
                type="text"
                :placeholder="$t('fieldsDescription.surname')"
              />
            </div>
          </div>
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.birthdate")
            }}</label>
            <div class="control">
              <input
                @invalid="invalidateForm"
                class="input"
                type="date"
                v-model="patientBirthdayFormatted"
              />
            </div>
          </div>
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.homeAddress")
            }}</label>
            <div class="control">
              <input
                @invalid="invalidateForm"
                class="input"
                type="text"
                v-model.trim="personalDataCopy.domicile"
                :placeholder="$t('fieldsDescription.address')"
              />
            </div>
          </div>
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.patientCode")
            }}</label>
            <div class="control">
              <input
                @invalid="invalidateForm"
                class="input"
                type="text"
                v-model.trim="personalDataCopy.PatientCode"
                :placeholder="$t('fieldsDescription.patientCode')"
                required
              />
            </div>
          </div>
          <div class="field">
            <label class="label has-text-left">{{
              $t("fieldsDescription.email")
            }}</label>
            <div class="control">
              <input
                @invalid="invalidateForm"
                class="input"
                type="email"
                :placeholder="$t('fieldsDescription.email')"
                v-model.trim="personalDataCopy.email"
              />
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
      <PatientSaveButton
        :awaitingResponse="awaitingResponse"
        :haveAllFieldsBeenFilledIn="this.haveAllFieldsBeenFilledIn()"
        :isDataEqualToTheSavedOnSubmit="this.isDataEqualToTheSavedOnSubmit()"
      />
    </form>
  </div>
</template>


<script>
import savePatientMixin from "./savePatientMixin.js";
import api from "../API/api.js";
import cloneDeep from "lodash/cloneDeep";
import PatientSaveButton from "./PatientSaveButton";

export default {
  emits: ["saveClickHandler"],
  props: ["patientToModify"],
  mixins: [savePatientMixin],
  components: { PatientSaveButton },
  data() {
    return {
      patientId: this.patientToModify.PatientID,
      awaitingResponse: false,
      errors: false,
      allowAnonymousCreation: true,
      personalDataCopy: cloneDeep(this.patientToModify.personalData),
      currentMonitoringData: cloneDeep(this.patientToModify.monitoringData),
    };
  },
  methods: {
    invalidateForm() {
      this.errors = true;
    },

    callApi() {
      // when updating patient data, enhance it with the deviceSN: this way, device.patient will be updated too on the backend
      const enhancedPersonalData = {
        ...this.personalDataToSend,
        monitoringData: { ...this.currentMonitoringData },
      };
      this.patientId
        ? this.modifyPatient(this.patientId, enhancedPersonalData)
        : this.createNewPatient();
    },

    haveAllFieldsBeenFilledIn() {
      if (this.allowAnonymousCreation) return this.personalDataCopy.PatientCode;
      else
        return (
          this.personalDataCopy.name &&
          this.personalDataCopy.surname &&
          this.personalDataCopy.sex &&
          this.personalDataCopy.birthplace &&
          this.personalDataCopy.birthdate &&
          this.personalDataCopy.address &&
          this.personalDataCopy.PatientCode &&
          this.personalDataCopy.phone
        );
    },

    isDataEqualToTheSavedOnSubmit() {
      return (
        JSON.stringify(this.patientToModify.personalData) ===
        JSON.stringify(this.personalDataCopy)
      );
    },

    async createNewPatient() {
      this.awaitingResponse = true;
      try {
        const res = await api.createPatient(this.personalDataToSend);
        this.$emit("saveClickHandler", {
          id: res.patient.id,
          personalData: this.personalDataCopy,
        });
        this.patientId = res.patient.id;
        if (!this.patientId)
          throw new Error("The server returned an empty patient");
      } catch (E) {
        this.$emit("errorOnSaveHandler", this.parseError(E));
      } finally {
        this.awaitingResponse = false;
        // return {};
      }
    },
    ISOdate(date) {
      if (!date || date == "Invalid Date") return "";
      else {
        return new Date(date).toISOString().substring(0, 10);
      }
    },
  },
  computed: {
    personalDataToSend() {
      return { personalData: this.personalDataCopy };
    },
    patientBirthdayFormatted: {
      get() {
        return this.personalDataCopy
          ? this.ISOdate(this.personalDataCopy.birthdate)
          : "";
      },
      set(value) {
        this.personalDataCopy.birthdate = value;
      },
    },
  },
};
</script>