var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(this.orderedDevices.length > 0)?_c('table',{staticClass:"table is-fullwidth is-fixed"},_vm._l((this.orderedDevices),function(device,idx){return _c('tr',{key:idx},[_c('td',{staticClass:"is-uppercase is-clipped"},[_c('p',[_vm._v(_vm._s(device.SerialNumber))]),(_vm.isAdmin)?_c('p',[_vm._v(_vm._s(device.client))]):_vm._e()]),_c('td',{staticClass:"is-clipped"},[(device.patient && device.patient.name)?_c('p',[_vm._v(" "+_vm._s(device.patient.name)+" "+_vm._s(device.patient.surname)),_c('br'),(device.patient && device.patient.id)?_c('a',{on:{"click":function($event){return _vm.$router.push({
                path: '/patient-form/',
                query: { patient: device.patient.id, currentStep: 5 },
              })}}},[_vm._v("("+_vm._s(_vm.displayPatientCode(device.lastContact, device.patient))+")")]):_c('span',[_vm._v("("+_vm._s(_vm.displayPatientCode(device.lastContact, device.patient))+")")])]):(
            (device.patient && device.patient.PatientCode) ||
            (device.lastContact && device.lastContact.patientCode)
          )?_c('p',{class:'is-size-7'},[(device.patient && device.patient.id)?_c('a',{on:{"click":function($event){return _vm.$router.push({
                path: '/patient-form/',
                query: { patient: device.patient.id, currentStep: 5 },
              })}}},[_vm._v("("+_vm._s(_vm.displayPatientCode(device.lastContact, device.patient))+")")]):_c('span',[_vm._v("("+_vm._s(_vm.displayPatientCode(device.lastContact, device.patient))+")")])]):_vm._e()]),_c('td',{staticClass:"is-uppercase is-clipped"},[(device.lastContact)?_c('p',[_vm._v(" "+_vm._s(_vm._f("formatTimestampAsDistance")(device.lastContact.timestamp))+" ")]):_vm._e(),(device.lastContact)?_c('p',{staticClass:"is-size-7"},[_vm._v(" "+_vm._s(_vm._f("formatTimestampAsDateTime")(device.lastContact.timestamp))+" ")]):_vm._e()]),_c('td',{staticClass:"is-uppercase is-clipped has-left-border"},[(device.lastContact)?_c('p',[_vm._v(" "+_vm._s(device.lastContact && device.lastContact.powerLevel)+"% ")]):_vm._e(),(_vm.isAdmin && device.lastContact && device.lastContact.location)?_c('p',[_c('a',{attrs:{"href":("https://www.openstreetmap.org/?mlat=" + (device.lastContact.latitude) + "&mlon=" + (device.lastContact.longitude) + "#map=9/" + (device.lastContact.latitude) + "/" + (device.lastContact.longitude)),"target":"_blank"}},[_vm._v(" "+_vm._s(device.lastContact.location))]),_vm._v(" ("+_vm._s(device.lastContact.country)+") ")]):_vm._e()]),_c('td',{staticClass:"is-clipped has-right-border"},[_vm._v(" "+_vm._s(device.lastContact && device.lastContact.apn)+" ")]),_c('td',{staticClass:"is-uppercase is-clipped"},[_vm._v(" "+_vm._s(device.lastContact && device.lastContact.softwareVersion)+" ")]),_c('td',{staticClass:"is-uppercase is-clipped"},[_c('button',{staticClass:"button is-success",on:{"click":function($event){return _vm.$emit('handleOpenLogsClick', device.SerialNumber)}}},[_vm._v(" "+_vm._s(_vm.$t("logs"))+" ")])])])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }