<template>
  <div :class="['modal', showLogs ? 'is-active' : '']">
    <div class="modal-background" @click="$emit('handleCloseLogsClick')"></div>
    <div class="modal-content larger">
      <div class="box">
        <div class="columns">
          <div class="column"></div>
          <div class="column is-half">
            <h1 class="title has-text-centered">
              {{ $t("logsOfDevices") }}
              {{ selectedDevice }}
            </h1>
          </div>
          <div class="column has-text-right">
            <button v-if="false" class="button is-success">
              {{ $t("download") }}
            </button>
          </div>
        </div>
        <div v-if="!loading">
          <div v-if="logsNotReadable">
            <h1 class="is-size-2 has-text-danger">Errore</h1>
            <p class="is-size-4">
              {{ $t("warnings.logsOfDevicesError") }}
            </p>
            <br />
          </div>
          <div v-if="noContent">
            {{ $t("warnings.noLogsOfDevices") }}
          </div>
          <div v-if="!noContent && !logsNotReadable">
            <div class="tabs is-boxed is-centered">
              <ul v-for="log in logs" :key="log.name">
                <li :class="{ 'is-active': selectedLog == log }">
                  <a
                    :class="{ 'is-active-text': selectedLog == log }"
                    @click="
                      selectedLog = log;
                      signedUrl = undefined;
                    "
                    >{{
                      log.name.includes("_")
                        ? log.name.substring(0, log.name.indexOf("_"))
                        : log.name
                    }}</a
                  >
                </li>
              </ul>
            </div>
            <div class="container">
              <div id="currentLog" class="has-text-left">
                <pre class="p-2 mb-1 has-text-left has-text-weight-bold">
                  {{ selectedLog.name }}
                </pre>

                <button
                  class="button is-small is-success"
                  @click="download(selectedLog.name)"
                >
                  {{ $t("download") }}
                </button>
                <span class="has-text-left">
                  <a class="p-3" v-if="this.signedUrl" :href="this.signedUrl">{{
                    $t("readyForDownload")
                  }}</a>
                </span>
                <pre class="has-text-left">{{
                  selectedLog.name.endsWith("enc")
                    ? $t("encryptedFile")
                    : selectedLog.content
                }}</pre>
              </div>
            </div>
          </div>
          <br />
        </div>
        <Loader v-else />
      </div>
    </div>
    <button
      class="modal-close is-large"
      @click="$emit('handleCloseLogsClick')"
      aria-label="close"
    ></button>
  </div>
</template>

<script>
import Loader from "@/shared/Loader.vue";
import api from "@/API/api.js";

export default {
  props: ["showLogs", "selectedDevice"],
  components: { Loader },
  data() {
    return {
      logs: [],
      selectedLog: {},
      loading: false,
      logsNotReadable: false,
      signedUrl: "",
    };
  },
  computed: {
    noContent() {
      return !this.logs || this.logs.length == 0;
    },
  },
  methods: {
    async download(logName) {
      try {
        this.signedUrl = await api.getDownloadUrlFor(
          this.selectedDevice,
          logName
        );
      } catch (E) {
        console.log(E.toString());
      }
    },
  },
  watch: {
    async selectedDevice() {
      if (this.showLogs) {
        try {
          this.loading = true;
          this.logs = await api.getLogsForDevice(this.selectedDevice);
          // decode Base64 encoded log content
          this.logs.forEach((log) => {
            log.content = atob(log.content);
          });
          this.selectedLog =
            this.logs.find((log) => log.name && log.name.includes("resmon")) ||
            this.logs[0];
        } catch (e) {
          console.log(e);
          this.logsNotReadable = true;
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
pre {
  white-space: pre-line;
}
.is-active-text {
  color: $restech-green !important;
}
.larger {
  width: 900px !important;
}
</style>