var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"table-container box mb-5"},[_c('table',{staticClass:"table is-striped is-hoverable is-fullwidth"},[(_vm.showHeader)?_c('TableHeading'):_vm._e(),_vm._l((this.summary),function(entry){return _c('tr',{key:entry.id,staticClass:"clickable",on:{"click":function($event){return _vm.$router.push(("/measurements/" + (entry.id)))}}},[_c('td',{attrs:{"colspan":"2"}},[(entry.patientName)?_c('p',[_vm._v(" "+_vm._s(entry.patientName)+" "+_vm._s(entry.patientSurname)+" ")]):(entry.device)?_c('p',[_vm._v(_vm._s(entry.device))]):_vm._e(),(entry.patientCode)?_c('p',{staticClass:"is-size-7"},[_vm._v(" "+_vm._s(entry.patientCode)+" ")]):_vm._e()]),_c('td',{staticClass:"is-capitalized has-text-weight-semibold",class:_vm.colorByStatus(entry.status)},[_c('p',[_vm._v(" "+_vm._s(_vm.wordByStatus(entry.status))+" ")]),(_vm.statusHasBeenSetByHuman(entry.status))?_c('p',{staticClass:"has-text-weight-normal is-size-7"},[(!_vm.statusChangedToday(entry.statusChangedAt))?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatTimestampAsDateTime")(entry.statusChangedAt))+" ")]):_c('span',[_vm._v(_vm._s(_vm._f("formatTimestampAsTime")(entry.statusChangedAt)))])]):_vm._e(),(entry.scheduledCall)?_c('p',{staticClass:"has-text-weight-normal is-size-7"},[_c('i',{staticClass:"fas fa-phone-volume lg pt-1"}),(_vm.isCallScheduledForToday(entry.scheduledCall))?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatTimestampAsTime")(entry.scheduledCall))+" ")]):_c('span',[_vm._v(_vm._s(_vm._f("formatTimestampAsDate")(entry.scheduledCall)))])]):_vm._e()]),_c('td',{class:{
            'to-manage':
              _vm.statusIsActive(entry.status) &&
              _vm.above_date_error_threshold(
                entry.lastMeasurementTimestamp,
                entry.patientMonitoringProtocol
              ),
            'orange-symptom':
              _vm.statusIsActive(entry.status) &&
              !_vm.above_date_error_threshold(
                entry.lastMeasurementTimestamp,
                entry.patientMonitoringProtocol
              ) &&
              _vm.above_date_warning_threshold(entry.lastMeasurementTimestamp),
          }},[_vm._v(" "+_vm._s(_vm._f("formatTimestampAsDistance")(entry.lastMeasurementTimestamp))+" "),(_vm.receivedToday(entry.lastMeasurementTimestamp))?_c('p',{staticClass:"is-size-7"},[_vm._v(" "+_vm._s(_vm._f("formatTimestampAsTime")(entry.localTimestamp))+" "+_vm._s(entry.deviceTimezone)+" ")]):_c('p',{staticClass:"is-size-7"},[_vm._v(" "+_vm._s(_vm._f("formatTimestampAsDate")(entry.localTimestamp))+" ")])]),_c('td',{class:{
            'to-manage':
              _vm.statusIsActive(entry.status) &&
              _vm.respiratoryIssue(entry.respiratoryAnomaly) &&
              entry.patientMonitoringProtocol !== 'Compliance',
          }},[_vm._v(" "+_vm._s(_vm._f("formatRespiratoryIssue")(entry.respiratoryAnomaly))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatValueToDecimal")(entry.RR))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatValueToDecimal")(entry.Vt))+" ")]),_c('td',{class:{
            'to-manage':
              _vm.statusIsActive(entry.status) &&
              entry.SpO2Issue &&
              _vm.protocolConsidersSaturation(entry.patientMonitoringProtocol),
          }},[_vm._v(" "+_vm._s(entry.SpO2Issue ? "<90%" : entry.SpO2 == "stable" ? "≥90%" : "-")+" ")]),_c('td',{class:{
            'to-manage':
              _vm.statusIsActive(entry.status) &&
              entry.temperatureIssue &&
              _vm.protocolConsidersTemperature(entry.patientMonitoringProtocol),
          }},[_vm._v(" "+_vm._s(entry.temperatureIssue ? ">37.5" : entry.temperature == "stable" ? "≤37.5" : "-")+" ")]),_c('td',{class:{
            'to-manage':
              _vm.statusIsActive(entry.status) &&
              _vm.protocolSupportsSymptoms(entry.patientMonitoringProtocol) &&
              entry.symptoms &&
              entry.symptoms.length > 0,
          }},[_vm._v(" "+_vm._s(entry.symptoms ? entry.symptoms.filter(function (s) { return _vm.symptom_is_not_temperature_or_saturation(s); } ).length > 0 ? _vm.$t("present") : _vm.$t("none") : "-")+" ")])])})],2)]),_c('error-modal',{attrs:{"errorMessage":this.error.message,"logoutOnClose":this.error.forceLogout}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }