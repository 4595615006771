/<template>
  <div>
    <table
      v-if="this.orderedDevices.length > 0"
      class="table is-fullwidth is-fixed"
    >
      <tr v-for="(device, idx) in this.orderedDevices" :key="idx">
        <td class="is-uppercase is-clipped">
          <p>{{ device.SerialNumber }}</p>
          <p v-if="isAdmin">{{ device.client }}</p>
        </td>
        <td class="is-clipped">
          <p v-if="device.patient && device.patient.name">
            {{ device.patient.name }} {{ device.patient.surname }}<br />
            <a
              v-if="device.patient && device.patient.id"
              @click="
                $router.push({
                  path: '/patient-form/',
                  query: { patient: device.patient.id, currentStep: 5 },
                })
              "
              >({{ displayPatientCode(device.lastContact, device.patient) }})</a
            ><span v-else
              >({{
                displayPatientCode(device.lastContact, device.patient)
              }})</span
            >
          </p>
          <p
            v-else-if="
              (device.patient && device.patient.PatientCode) ||
              (device.lastContact && device.lastContact.patientCode)
            "
            :class="'is-size-7'"
          >
            <a
              v-if="device.patient && device.patient.id"
              @click="
                $router.push({
                  path: '/patient-form/',
                  query: { patient: device.patient.id, currentStep: 5 },
                })
              "
              >({{ displayPatientCode(device.lastContact, device.patient) }})</a
            ><span v-else
              >({{
                displayPatientCode(device.lastContact, device.patient)
              }})</span
            >
          </p>
        </td>
        <td class="is-uppercase is-clipped">
          <p v-if="device.lastContact">
            {{ device.lastContact.timestamp | formatTimestampAsDistance }}
          </p>
          <p v-if="device.lastContact" class="is-size-7">
            {{ device.lastContact.timestamp | formatTimestampAsDateTime }}
          </p>
        </td>
        <td class="is-uppercase is-clipped has-left-border">
          <p v-if="device.lastContact">
            {{ device.lastContact && device.lastContact.powerLevel }}%
          </p>
          <p
            v-if="isAdmin && device.lastContact && device.lastContact.location"
          >
            <a
              :href="`https://www.openstreetmap.org/?mlat=${device.lastContact.latitude}&mlon=${device.lastContact.longitude}#map=9/${device.lastContact.latitude}/${device.lastContact.longitude}`"
              target="_blank"
            >
              {{ device.lastContact.location }}</a
            >&nbsp;({{ device.lastContact.country }})
          </p>
        </td>
        <td class="is-clipped has-right-border">
          {{ device.lastContact && device.lastContact.apn }}
        </td>
        <td class="is-uppercase is-clipped">
          {{ device.lastContact && device.lastContact.softwareVersion }}
        </td>
        <td class="is-uppercase is-clipped">
          <button
            class="button is-success"
            @click="$emit('handleOpenLogsClick', device.SerialNumber)"
          >
            {{ $t("logs") }}
          </button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { mapGetters } from "vuex";
export default {
  emits: ["handleOpenLogsClick"],
  props: {
    devices: Array,
  },
  methods: {
    displayPatientCode(lastContact, patient) {
      if (
        lastContact &&
        patient &&
        patient.PatientCode &&
        lastContact.patientCode !== patient.PatientCode // NB non può succedere, alla ricezione di un contatto aggiorniamo patient.PatientCode
      )
        return `Device: ${lastContact.patientCode}, Resolve: ${patient.PatientCode}`;
      else if (lastContact && lastContact.patientCode)
        return lastContact.patientCode;
    },
  },
  computed: {
    ...mapGetters(["hasRoleAdmin"]),
    isAdmin() {
      return this.hasRoleAdmin;
    },
    noContent() {
      return this.devices.length == 0;
    },

    orderedDevices() {
      // order by last contact sent if available, for inactive devices order by S/N
      const copy = cloneDeep(this.devices);
      return copy.sort(function (a, b) {
        if (a.lastContact && b.lastContact)
          return (
            new Date(b.lastContact.timestamp) -
            new Date(a.lastContact.timestamp)
          );
        else if (a.SerialNumber && b.SerialNumber)
          return a.SerialNumber - b.SerialNumber;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
td.has-left-border {
  border-left: 1px solid #dbdbdb !important;
}
td.has-right-border {
  border-right: 1px solid #dbdbdb !important;
}
a {
  color: $restech-green !important;
}
a:hover {
  text-decoration: underline;
}
</style>